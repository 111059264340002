.login-container {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 68%;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .card-container {
    max-width: 34rem;
    padding: 2.5rem;
  }

  &__login-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    // margin-bottom: 30rem;
    box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
  }

  &__logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  &__form_fields {
    @include flex-center;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  &__login_button {
    align-self: flex-end;
  }

  &__register {
    color: $white;
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
  }

  &__corner-logo {
    position: absolute;
    bottom: 7rem;
    right: 7rem;
  }
}
