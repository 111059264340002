.reports_list {
  color: red;
  &__table {
    border: 1 px solid $black;
    & td {
      padding: 1rem;
      text-align: center;
      border-bottom: 1px solid $gray;
      background: $white;
      color: $variant-5;
    }
  }

  &__tools {
    cursor: pointer;
    transition: all 0.5s;
    color: $variant-5;
    font-size: 1.2em;

    &:hover {
      color: $secondary;
    }
  }

  &__approve {
    &:hover {
      color: $success;
    }
  }

  &__approved {
    color: $success;
  }
  &__disapproved {
    color: $danger;
  }
}
