.header-container {
  height: 10rem;
  background: $white;
  padding: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 8rem;
    i {
      font-size: 3rem;
      color: $gray-light;
      cursor: pointer;
      transition: 0.5s;
    }

    i:hover {
      color: $secondary;
    }
  }

  &__brand {
    display: flex;
    align-items: center;
    flex: 2;
    padding-left: 3.5rem;
    margin-right: 3.5rem;
    & img {
      filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.7));
    }
  }

  &__search {
    width: 20% !important;
    margin: -0.8rem auto 0 auto;
    transition: 1s;
  }

  &__search:focus {
    width: 50% !important;
    border-color: $blue-fade;
    border-radius: 0;
  }

  &__icon-1 {
    margin-top: -0.7rem;
    font-size: 2.1rem;
  }

  &__icon-2 {
    margin-top: 0;
  }
}
