.back-to-top {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 20;
  opacity: 0;
  transition: all 0.5s;
  & > i {
    font-size: 5rem;
    color: $blue-deep;
    transition: 0.4s;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }
  }
}
