.card {
  padding: 2rem;
}

.card-container {
  min-width: 32rem;
  min-height: 15rem;

  border-radius: 0.5rem;
  overflow: hidden;

  &__header {
    padding: 1rem;

    color: $white;
  }

  &__card-item {
    min-height: 45rem;
    flex-grow: 1;
    background: $white;
    flex-grow: 1;
  }

  &__extras {
    margin: 1rem 0;
  }
}
