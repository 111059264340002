.bubble-container { 
    
    font-family:  consolas;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    span {
        position:absolute;
        bottom:50px;
        background:transparent;
        border-radius: 50% ;
        pointer-events: none;
        box-shadow: inset 0 0 10px rgba(255,255, 255, 0.5);
        animation: animate 4s linear infinite;

    }
}


@keyframes animate {
    0% {
        transform: translateY(0%) scale(1);
        opacity: 1;
    } 

    100% {
        transform: translateY(-1200%) scale(.5);
        opacity:1;
    }
} 