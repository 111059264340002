.manage {
  min-height: 100vh;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 1rem;
  padding: 2.5rem 2.5rem;

  &__buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  &__buttons {
    margin: 3rem 0 3rem 0rem;
    display: flex;

    gap: 1.5rem;

    & .button:hover {
      background-color: $blue-fade;
      color: #fff;
    }

    & .selected {
      background-color: $blue;
      color: #fff;
    }
  }

  &__sellers {
    margin: 2.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    & input[type='text'],
    input[type='password'],
    select,
    .button {
      width: 30rem;
    }
    & .button {
      width: 30rem;
      color: #fff;
    }
  }
}
