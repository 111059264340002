.secured-container {
  background: $gray-light-2;
  min-height: 100vh;
  &__main-content {
    margin: 2rem 0 0 0;
    display: flex;
  }

  &__menu {
    flex-basis: 20%;
    background: $white;
    padding: 1.5rem 0 4rem 0;
    border-radius: 0.7rem;
    margin: 0 1rem;
    position: sticky;
    top: 2rem;
    min-width: 15rem;
    max-width: 1rem;
    overflow: hidden;
    transition: 1s;
    height: 95vh;
    box-shadow: 1px 1px 1px 0px #ccc;
  }

  &--close {
    max-width: 4.6rem !important;
    min-width: 4.6rem !important;
    border-radius: 0.5rem;
    // border:1px solid $blue;
  }

  &__close {
    display: flex;
    justify-content: flex-start;
    padding: 0 1.2rem 0 0;
    align-items: center;
    margin: 0 0 1.5rem 0;
    cursor: pointer;
    height: 2rem;
  }

  &__menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    gap: 1rem;

    margin: 0 0 0 0;

    cursor: pointer;
    transition: 0.3s;
    position: relative;

    i {
      font-size: 2.3rem;
      color: $blue-fade;
      width: 3rem;
    }

    & .fa-calendar-alt {
      margin-left: 0.4rem;
    }

    & .fa-arrow-circle-down {
      font-size: 1.7rem;
      transition: 0.5s;
      margin-left: auto;

      transform-origin: center center;
    }
  }

  &__humburger {
    border-bottom: 3px solid $blue-fade;
    border-radius: 0.5rem;
    width: 2.2rem;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
  }
  &__humburger::before {
    content: '';
    position: absolute;
    border-radius: 0.5rem;
    top: -6px;
    width: 2.2rem;
    display: block;
    border-bottom: 3px solid $blue-fade;

    cursor: pointer;
    transition: 0.5s;
  }
  &__humburger::after {
    content: '';
    position: absolute;
    top: 6.3px;
    width: 2.2rem;
    display: block;
    border-radius: 11.5rem;
    border-bottom: 3px solid $blue-fade;
    cursor: pointer;
    transition: 0.5s;
  }

  &__menu--closed {
    border: 0px;
    transition: 0.3s;
  }

  &__menu--closed::before {
    transform: rotate(135deg) !important;
    top: 0px;
  }

  &__menu--closed::after {
    transform: rotate(222deg) !important;
    top: 0px;
  }

  &__menu-icon-container {
    width: 2.5rem;
    border-top: 10px;
  }

  &__menu-item:hover,
  &__selected {
    background: $blue-light;
    border-left: 3px solid $blue-fade;
  }

  &__submenu {
    margin: 0.6rem 0 1rem 3rem;
    opacity: 0;
    transition: all 1s;
    transform: scale(0);
  }

  &__submenu-item {
    padding: 0.8rem;
    text-align: left;
    cursor: pointer;
    transition: 0.65s;
    border-radius: 5px;
    margin-right: 1rem;
  }

  &__submenu-item:hover,
  &__submenu-item-selected {
    background: rgba($gray-light, 0.4);
  }

  &__submenu-item-selected {
    opacity: 1;
    background: $gray-light;
  }
}

.private-route-container {
  flex-grow: 2;
  margin-right: 1rem;
  border-radius: 0.5rem;

  li {
    padding: 1rem;
  }

  & ul li:hover {
    background: $gray-light;
  }
}
