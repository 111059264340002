body.mycave {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
}

.customer-area .header-container {
  background: transparent !important;
  margin-top: 2rem;

  .header-container__toolbar {
    width: 78rem !important;
    background: rgba(0, 0, 0, 0.3);
    padding: 1rem;
    border-radius: 1rem;

    & > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: #fff;
      width: 100%;
      margin-left: 1rem;
      justify-content: space-around;
      align-items: center;
      gap: 1rem;
      & > div {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
      }
      & i {
        font-size: 2rem !important;
      }
    }
  }

  .header-container__item {
    padding: 0.8rem;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }
}

.home-page-container {
  overflow: hidden;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   overflow: hidden;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   overflow: hidden;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  &__add-item {
    cursor: pointer;
    transition: all 0.5s linear;
    &:hover {
      background-color: $white;
      color: #000;
      font-weight: bold;
      border-color: #000;
      border-right-color: #000 !important;
    }
  }

  & .header-container {
    background-color: transparent !important;
  }

  &__customer_add_item {
    width: 50rem;

    h2 {
      font-size: 1.6rem !important;
      margin: -2rem 0 1.5rem 0;
      width: 100%;
      padding: 0 0 1rem 0;
      border-bottom: 2px solid $gray-light;
    }

    p {
      margin: 2rem 0 2rem 0;
    }

    & .add-item-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      & button {
        color: #fff;
      }
    }
  }
}

.cards-container {
  position: relative;
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  justify-content: center;

  & > div {
    flex-grow: 1;
    flex-basis: 31%;
  }

  &__card {
    position: relative;
    min-width: 45rem;

    border-radius: 1rem;

    margin: 30px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    display: flex;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    overflow: hidden;
    // try different styles
    background: rgba(0, 0, 0, 0.6);
  }

  &__content {
    padding: 1.5rem;
    overflow: hidden;
    transition: all 0.5s;
    position: relative;
    width: 100%;
    color: #fff;
    overflow: hidden;

    & h2 {
      min-width: 20rem;
      border-radius: 3px;
      padding: 0.6rem;
      // padding-left: 2rem;
      background: rgba(255, 255, 255, 0.15);
      box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.3);
      font-size: 1.5rem;
      pointer-events: none;

      max-width: 25rem;
      text-align: left;
      letter-spacing: 0.3 rem;
      text-align: center;
      margin: 3rem 0 3rem 1.5rem;
      color: $white;
    }
  }

  &__items {
    min-height: 44.5rem;
    display: flex;
    & > div {
      flex-grow: 1;
    }
  }

  &__inventory-list {
    border-spacing: 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    width: 100%;
    // border-collapse: collapse;

    text-align: left;
    & th,
    & td {
      padding: 0.9rem;
      border-bottom: 3px solid $gray-light;
      margin: 2rem 2rem;
      border-bottom-right-radius: 1rem;
      border-radius: 1rem;
    }

    & th {
      background: linear-gradient(45deg, #f7f7f7, #9b9b9b);
      background-size: 200% 400%;
      animation: GradientBackground 10s ease infinite;
      color: #000;
      border-radius: 3px;

      margin: 0 0 12rem 0;
      clip-path: polygon(100% 11%, 98% 90%, 0 100%, 0 52%, 0% 0%);
    }

    & tbody tr {
      position: relative;
      background: rgba(0, 0, 0, 0.5);

      transition: 1s;
    }

    & tr:hover {
      background: rgba(0, 0, 0, 0.7);
      transition: 0.5s;
    }

    & th:nth-child(4),
    td:nth-child(4),
    th:nth-child(5),
    td:nth-child(5) {
      text-align: center;
    }
  }

  & td:first-child {
    border-left: 3px solid #fff;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  & td:last-child {
    border-right: 3px solid #fff;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  &__toolbar {
    display: flex;
    gap: 1rem;
    margin: 3rem 0 3rem 0;
    align-items: center;
    justify-content: space-between;
    min-height: 5rem;
  }

  &__filters {
    padding: 1rem 1rem 2rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__search {
    background: rgba(0, 0, 0, 0.3) !important;
    border: 1px solid transparent rgba(0, 0, 0, 0.8);
    color: #fff;
    width: 15rem !important;
    transition: 1s;

    &:focus {
      width: 40rem !important;
    }
  }

  & .btn {
    min-width: 12rem;
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    cursor: pointer;

    transition: 0.7s;

    &:hover {
      background: rgba(255, 255, 255, 0.8);
      color: #000;
      font-weight: 700;
      border: 1px solid transparent;
    }
  }

  & .input-range__label {
    font-size: 1.2rem !important;
  }
  & .input-range__track--active,
  & .input-range__slider {
    background-color: $gray-light;
    border: 1px solid $gray-light;
  }

  & .input-range__label-container {
    top: -6px;
    font-size: 1.5rem;
  }

  & .input-range__label--max,
  & .input-range__label--min {
    height: 0rem;
  }

  &__search-container {
    width: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Pagination-container {
  display: flex;
  gap: 1rem;
  margin: 3rem 0;
  flex-wrap: wrap;

  &__item {
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    font-size: 1.5rem;
    border-radius: 5px;
    width: 25px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
  }
  &__selected {
    background: rgba(255, 255, 255, 0.44) !important;
    color: #000;
  }
}

// MA CAVE

.ma-cave {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__tabs {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__tab {
    background: rgba(0, 0, 0, 0.5);
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.5s;
    position: relative;
    &--selected,
    &:hover {
      background: rgba(255, 255, 255, 0.5);
      // clip-path: polygon(100% 11%, 98% 90%, 0 100%, 0 52%, 0% 0%);
      transform: skew(-7deg);
    }

    & span {
      position: absolute;
      left: -0.5rem;
      top: -1rem;

      padding: 0.72rem;
      font-size: 0.7em;
      border-radius: 50%;
      border: 2px solid rgba(0, 0, 0, 0.7);
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .blinker {
    animation-name: blink;
    animation-duration: 1s;
    animation: blink 2s infinite;
  }

  @keyframes blink {
    0% {
      background-color: rgba(0, 0, 0, 0.5) !important;
      transform: scale(1);
      transform: skew(0deg);
      color: rgba(0, 0, 0, 0.5) !important;
    }
    50% {
      background: rgba(255, 255, 255, 0.5) !important;
      transform: scale(1.05) skew(-7deg);
      color: rgba(255, 255, 255, 0.5) !important;
    }
    100% {
      background-color: rgba(0, 0, 0, 0.5) !important;
      transform: scale(1);
      transform: skew(0deg);
      color: rgba(0, 0, 0, 0.5) !important;
    }
  }

  .tag-white {
    background: rgba(255, 255, 255, 0.65);
    color: #000;
  }

  .tag-red {
    background: rgba($danger, 0.75);
    color: $white;
  }

  &__content {
    margin: 1rem 0 0 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    min-height: 52rem;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: -0.3rem 0.3rem 0.1rem 0rem rgba(255, 255, 255, 0.6),
      -0.5rem 0.5rem 0.1rem 0rem rgba(0, 0, 0, 0.6);
  }

  & .home-page-container__add-item {
    cursor: default !important;
  }
}

//  pending requests

.pending-requests {
  // display: flex;
  gap: 1rem;

  &__buttons {
    display: flex;
    gap: 1rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    & button {
      color: #fff;
    }

    & input {
      width: 9rem;
      margin-right: 7px;
      text-align: center;
    }
  }

  &__orders-list {
    flex-basis: 75%;
  }

  &__split-content {
    display: flex;
    & > div {
      flex-grow: 1;
    }
  }
}

.pending-orders {
  flex-basis: 20%;
  margin: 3rem 0rem;

  &__header {
    background: #d8e1eb;
    padding: 1rem;
    margin: 0 0 2rem 0;
  }

  & > div {
    flex-grow: 1;
  }

  &__no-offers {
    min-height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 3rem;
    color: $gray-light;
  }

  &__order-list {
    display: flex;
    gap: 1rem;
    & > div {
      text-align: center;
      padding: 1rem;
      border-bottom: 1px solid $gray-light;
    }
  }
}

.user-info {
  padding: 1.5rem;
  display: flex;
  gap: 2rem;

  & > div {
    flex-grow: 1;
  }
  & .flex {
    margin-bottom: 1rem;
  }

  &__container {
    label {
      width: 26rem;
    }
  }

  &__form-errors {
    padding: 1rem;
    min-width: 43rem;
    gap: 2rem;
  }

  &__form-success {
    min-width: 43rem;
    display: flex;
    justify-content: center;
    align-items: center;
    & .user-info__error-item {
      border: 2px solid $success;
    }
  }

  &__error-item {
    border: 2px solid #daa520;
    padding: 1.2rem;
    min-width: 43rem;
    border-radius: 0.5rem;
    text-align: center;
  }

  &__buttons {
    margin: 0.5rem 0 2rem 0;
    .button {
      width: 25rem;
    }
  }

  &__passowrd-change {
    padding: 0.8rem;
    border-radius: 0.8rem;
    margin: 0 0 0 2rem;
    width: 35rem;
    text-align: center;
    border: 2px solid $danger !important;
  }

  &__passowrd-change-success {
    padding: 1.8rem;
    border-radius: 0.8rem;
    margin: 0 0 0 9rem;
    width: 35rem;
    font-size: 1.1em;
    text-align: center;
    border: 2px solid $success !important;
  }
}

.deposit-history {
  &__no-history {
    text-align: center !important;
    font-size: 1.3em !important;
    padding: 0 0 2rem 0 !important;
  }
}

.factures {
  &__download {
    width: 1rem;
    cursor: pointer;
    text-align: center;
    & i {
      font-size: 1.6em;
      transition: all 0.5s;

      &:hover {
        color: gold;
        transform: scale(1.3);
      }
    }
  }
}

.withdraw-model {
  & .add-item-buttons {
    margin: 2rem 0 0 0;
  }
}

.is-logged {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: #000;
}
