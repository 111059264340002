// COLORS
// $white: #fff;
// $black: #000;
// $black-dark: #050e1f;
// $blue: #3172eb;
// $blue-dark: #0a2378;
// $gray: #eee;
// $blue-light: #e9ecf3;
// $gray-light: #d8e1eb;
// $gray-light-2: #f2f6f9;
// $danger: #d15a52;
// $blue-fade: #7fb0da;
// $success: #2d8f3b;
// $info: #f3c200;
// $blue-deep: #67809f;
// $turq: #1bbc9b;
// $gray-deep: #95a5a6;

// $transparent-white: rgba(255, 255, 255, 0.2); ;

// COLORS
$white: #fff;
$black: #000;
$gray-light: #e5e5e5;
$gray: #989898;
$primary: #ffbe00;
$secondary: #0076ff;
$light-blue: #f1f7ff;
$green: #81b290;
$color-dark: #333333;
$color-purple: #8159b1;
// text
$text-1: #aeaeae;
$text-2: #7d7d7d;

// variants
$variant-1: #999999;
$variant-2: #0076fe;
$variant-3: #8bab95;
$variant-4: #f86060;
$variant-5: #333333;
$variant-6: #283646;
$variant-7: #eee;
$variant-8: #f2f2f2;

//
// COLORS
$white: #fff;
$black: #000;
$black-dark: #050e1f;
$blue: #3172eb;
$blue-dark: #0a2378;
$gray: #eee;
$blue-light: #e9ecf3;
$gray-light: #d8e1eb;
$gray-light-2: #f2f6f9;
$danger: #d15a52;
$error: #d15a52;
$blue-fade: #7fb0da;
$success: #2d8f3b;
$info: #f3c200;
$blue-deep: #67809f;
$turq: #1bbc9b;
$gray-deep: #95a5a6;

$transparent-white: rgba(255, 255, 255, 0.2);

$transparent-white: rgba(255, 255, 255, 0.2);

// FONTS SIZE
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 4rem;
$gutter-horizontal: 6rem;

$shadow-box-default: 0 7px 12px rgba(0, 0, 0, 0.4);
$shadow-box-default-high: 0 1px 20px rgba(0, 0, 0, 0.4);

// BREAK-POINTS
$bp-1300: 1300px;
$bp-1100: 1150px;
$bp-1000: 1000px;
$bp-860: 860px;
$bp-800: 800px;
$bp-710: 710px;
