.customers-details {
  &__tabs {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    button {
      color: black;
      font-size: 1.8rem;
    }
    button.selected {
      background: $blue-fade;
      color: $white;
    }
  }

  &__details {
    @include flexWarp;
    margin-bottom: 1rem;
  }

  &__content {
    margin: 2rem 0;
  }

  &__extras {
    display: flex;
    flex-direction: column;
    & > div {
      margin: 1.2rem 0;
    }
  }

  &__personal {
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;

    flex-direction: column;
    & > div {
      flex-grow: 1;
    }
  }

  &__form-group {
    display: flex;
    transition: all 0.5;
    align-items: center;
    background: $white;

    &:hover {
      background: rgba($blue-deep, 0.6) !important;
      transition: all 0.5s;
      color: White;
    }
    & button {
      width: 80%;
      margin: 0 auto;
    }
    & > div {
      display: flex;

      align-items: center;
    }
    justify-content: stretch;
    & > div {
      flex-grow: 1;
      flex-basis: 50%;
      padding: 1rem;
      min-height: 5rem;
    }
    & button {
      color: $white !important;
      & > i {
        color: $white;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 2rem 2rem 0;
    button {
      color: $white !important;
      width: 18rem;
    }
  }

  // logging History

  &__history {
    background: #fff;
    display: flex;
    align-items: center;
    gap: 1rem;
    &.header {
      background: $gray-light;
    }

    & > div {
      padding: 0.7rem;
      flex-grow: 1;
      border-bottom: 1px solid $gray-light;
    }
  }
}

.deposits {
  padding: 2rem;
  &__add {
    display: flex;
    gap: 0.7rem;
    flex-direction: column;
    width: 35rem;
  }
  &__add-header {
    background: $info;
    padding: 1rem;
    color: #fff;
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
    font-weight: 700;
  }

  &__balance {
     width: 35rem;
     padding:1rem;
  }

  &__balance-item {
    padding:1rem;
    font-weight:bold;
  }
  &__buttons {
    background: #eee;
    padding: 0.8rem;
    border-radius: 0.5px;
    margin: 0.5rem 0 0 0;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    .button {
      color: #fff;
    }
  }

  &__add-new-deposit {
    display: flex;
    justify-content: flex-start;
    gap: 4rem;
  }

  &__new-despoit-errors {
    color: #fff;
    font-weight: 800;
    letter-spacing: 3px;
    height: 4rem;
    width: 35rem;
    border-radius: 0.5rem;
    display: flex;
    margin: 0 0rem 10rem 0;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid $danger;
    margin: 4.5rem 0 0 0;
    width: 50%;
  }

  &__logs {
    margin: 2rem 0 0 0;
  }

  &__logs-header {
    background: $blue-deep;
    padding: 0.4rem;
    color: #fff;
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin: 0 0 10px 0;
  }

  &__log-item {
    display: flex;
    gap: 1rem;
    border-radius: 0.5rem;
    padding: 1rem;
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    & > div {
      width: 20rem;
    }
  }
  &__log-item-header {
    background: $info;
    margin: 2rem 0 0 0;
    color: #fff;
    border-radius: 0.5rem;
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin: 0 0 12px 0;
  }
}
