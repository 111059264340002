.file-upload {
  &__label {
    display: inline-block;
    background-color: $secondary;
    padding: 0.3rem 0.5rem;
    color: #fff;
    cursor: pointer;
  }

  & input[type='file'] {
    display: none;
  }
}
