.register {
  P,
  label {
    font-size: 1.5rem;
    color: $white;
    align-self: flex-start;
    margin: 0.8rem 1rem;
  }

  &__buttons {
    @include flex-center;
    width: 100%;
    justify-content: space-between;
  }

  &__login-info {
    margin-bottom: 0 !important;
    justify-content: flex-start !important;
    margin-top: 18rem;
  }

  &__form_fields {
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }

  &__server-response {
    padding: 1rem;
    color: #fff;
    text-align: center;
  }
}
