.reports {
  margin: 1rem;
  &__toolbar {
    background-color: #fff;
    padding: 1rem;
  }

  &__container {
    background: #fff;
    width: 100%;
    padding: 0.9rem;
    padding-bottom: 4rem;
    box-shadow: 5px 8px 13px rgba(0, 0, 0, 0.3);
  }
  &__container_pdf {
    background-size: cover;
    width: 100%;

    padding: 1em;
    padding-bottom: 240rem;
  //  box-shadow: 5px 8px 13px rgba(0, 0, 0, 0.3);
  }
  &__content {
  }

  &_text {
    font-size: large;
    color: #0003c2;
  }

  &_text2 {
    font-size: small;
    color: red;
  }





  &__table {
    border-collapse: collapse;
    border: 1px solid rgba(15, 136, 239, 0.8);
    width: 100%;
    & td {
      border: 1px solid rgba(15, 136, 239, 0.5);
      padding: 0.3em;
    }
    &--dark {
      color: $black;
      border-color: $black;
      border: 1px solid rgba(0, 0, 0, 0.8);

      & td {
        border: 1px solid rgba(0, 0, 0, 0.8);
      }
    }

    & table {
      border-collapse: collapse;
    }
  }
  &__table-header {
    font-size: 2rem;
  }
  &__submit {
    z-index: 1;
    bottom: 20px;
    position: fixed;
  }
}





.table-no-border {
  & td {
    border: none !important;
  }

}


.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}