/* Reset */

:root {
  // COLORS
  --primary-color: #71b9ad;
  --secondary-color: var(--default-font-color);
  // Shadows
  --shadow-color: #00000033;
  // Fonts
  --default-font-color: #707070;
}

*,
*::after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:link,
*:visited,
a {
  color: $white;
  text-decoration: none;
}

html {
  direction: rtl;
  font-size: 61.5%;
  @include respond(tab-land) {
    font-size: 61.5%;
  }
  @include respond(tab-port) {
    font-size: 52%;
  }

  @include respond(big-desktop) {
    font-size: 65%;
  }

  @include respond(phone) {
    font-size: 42.8%;
  }
}

body {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: $default-font-size;
  color: $variant-1;
  box-sizing: border-box;
  min-height: 100vh;
  @include respond(tab-port) {
    max-width: 100%;
  }
  margin: 0 auto;
}

.wrapper {
  width: 1546px;
  max-width: calc(100% - 120px);
  margin: 0 auto;
  padding: 1rem 0;
  position: relative;
  @include respond(tab-land) {
    max-width: calc(100% - 80px);
  }
  @include respond(phone) {
    max-width: calc(100% - 10px);
  }
}

//  CodeDB Custom classes

h1 {
  font-size: 2rem;
  font-weight: 700;
  color: $black;
}

h2 {
  font-size: 1.8rem;
  font-weight: 700;
  color: $black;
}

h3 {
  font-size: 1.4rem;
  font-weight: 700;
  color: $black;
}

p {
  color: $black;
}
